<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('print_setting.print_settings_details') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('print_setting.template') }}</label>
                <select class="custom-select" v-model="data.header_temp">
                  <option v-for="(template, index) in template_list" :value="template.id" :key="template.id">{{ template.title }}</option>
                </select>
                <span v-if="validation && validation.header_temp" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.header_temp[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5" v-if="data.header_temp == 0 && isFieldHide('header_height')">
                <label>{{ $t('print_setting.header_height') }}</label>
                <input type="number" class="form-control" v-model="data.header_height">
                <span v-if="validation && validation.header_height" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.header_height[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5" v-if="isFieldHide('print_size')">
                <label>{{ $t('print_setting.print_size') }} ( {{ $t('print_setting.mm') }} )</label>
                <input type="number" class="form-control" v-model="data.print_size">
                <span v-if="validation && validation.print_size" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.print_size[0] }}
                </span>
              </div>
              <div v-if="isFieldHide('is_multi_language_label')" class="col-lg-6 mb-5">
                <label>{{ $t('print_setting.is_multi_language_label') }}</label>
                <b-form-checkbox size="lg" v-model="data.is_multi_language_label" name="check-button" switch></b-form-checkbox>
              </div>
              <div v-if="isFieldHide('is_use_watermark')" class="col-lg-6 mb-5">
                <label>{{ $t('print_setting.is_use_watermark') }}</label>
                <b-form-checkbox size="lg" v-model="data.is_use_watermark" name="check-button" switch></b-form-checkbox>
              </div>

              <div  v-if="isFieldHide('is_banking_details')" class="col-lg-6 mb-5">
                <label>{{ $t('print_setting.is_banking_details') }}</label>
                <b-form-checkbox size="lg" v-model="data.is_banking_details" name="check-button" switch></b-form-checkbox>
              </div>
              <div v-if="isFieldHide('is_signature')" class="col-lg-6 mb-5">
                <label>{{ $t('print_setting.is_signature') }}</label>
                <b-form-checkbox size="lg" v-model="data.is_signature" name="check-button" switch></b-form-checkbox>
              </div>
              <div v-if="isFieldHide('is_show_contact')" class="col-lg-6 mb-5">
                <label>{{ $t('print_setting.is_show_contact') }}</label>
                <b-form-checkbox size="lg" v-model="data.is_show_contact" name="check-button" switch></b-form-checkbox>
              </div>
              <div v-if="isFieldHide('is_payment_details')" class="col-lg-6 mb-5">
                <label>{{ $t('print_setting.is_payment_details') }}</label>
                <b-form-checkbox size="lg" v-model="data.is_payment_details" name="check-button" switch></b-form-checkbox>
              </div>
              <div v-if="isFieldHide('is_repeated_header')" class="col-lg-6 mb-5">
                <label>{{ $t('print_setting.is_repeated_header') }}</label>
                <b-form-checkbox size="lg" v-model="data.is_repeated_header" name="check-button" switch></b-form-checkbox>
              </div>
              <div v-if="isFieldHide('is_repeated_footer')" class="col-lg-6 mb-5">
                <label>{{ $t('print_setting.is_repeated_footer') }}</label>
                <b-form-checkbox size="lg" v-model="data.is_repeated_footer" name="check-button" switch></b-form-checkbox>
              </div>
              <div v-if="isFieldHide('is_empty_row')" class="col-lg-6 mb-5">
                <label>{{ $t('print_setting.is_empty_row') }}</label>
                <b-form-checkbox size="lg" v-model="data.is_empty_row" name="check-button" switch></b-form-checkbox>
              </div>
              <div v-if="isFieldHide('is_break_before_footer')" class="col-lg-6 mb-5">
                <label>{{ $t('print_setting.is_break_before_footer') }}</label>
                <b-form-checkbox size="lg" v-model="data.is_break_before_footer" name="check-button" switch></b-form-checkbox>
              </div>
              <div v-if="isFieldHide('is_show_customer_details')" class="col-lg-6 mb-5">
                <label>{{ $t('print_setting.is_show_customer_details') }}</label>
                <b-form-checkbox size="lg" v-model="data.is_show_customer_details" name="check-button" switch></b-form-checkbox>
              </div>
              <div v-if="isFieldHide('is_show_terms_and_conditions')" class="col-lg-6 mb-5">
                <label>{{ $t('print_setting.is_show_terms_and_conditions') }}</label>
                <b-form-checkbox size="lg" v-model="data.is_show_terms_and_conditions" name="check-button" switch></b-form-checkbox>
              </div>

              <div v-if="isFieldHide('products_template_design')" class="col-lg-6 mb-5">
                <label>{{ $t('print_setting.products_template_design') }}</label>
                <b-form-group label="" v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="data.products_template_design" :aria-describedby="ariaDescribedby" name="some-radios" value="1">{{ $t('print_setting.card_design') }}</b-form-radio>
                  <b-form-radio v-model="data.products_template_design" :aria-describedby="ariaDescribedby" name="some-radios" value="2">{{ $t('print_setting.table_design') }}</b-form-radio>
                </b-form-group>              </div>



              <div class="col-lg-12 mb-5" v-if="data.is_break_before_footer && isFieldHide('footer_font_color')">
                <label>{{ $t('print_setting.footer_font_color') }}</label>
                <input type="color" class="form-control" v-model="data.footer_font_color">
                <span v-if="validation && validation.footer_font_color" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.footer_font_color[0] }}
                </span>
              </div>
              <div v-if="isFieldHide('usage_policy')" class="col-lg-12 mb-10">
                <label>{{ $t('print_setting.usage_policy') }}</label>
                <vue-editor ref="editor" v-model="data.usage_policy"></vue-editor>
<!--                <textarea class="form-control" v-model="data.usage_policy"></textarea>-->
                <span v-if="validation && validation.usage_policy" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation.usage_policy[0] }}
                                    </span>
              </div>
              <div v-if="isFieldHide('description')" class="col-lg-12 mb-10">
                <label>{{ $t('print_setting.description') }}</label>
                <textarea class="form-control" v-model="data.description.ar"></textarea>
                <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation.description[0] }}
                                    </span>
              </div>
              <div v-if="isFieldHide('description')" class="col-lg-12 mb-10">
                <label>{{ $t('print_setting.description_en') }}</label>
                <textarea class="form-control" v-model="data.description.en"></textarea>
                <span v-if="validation && validation.description_en" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation.description_en[0] }}
                                    </span>
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="d-flex col-lg-12 mb-5">
                <b-form-group label="" v-slot="{ ariaDescribedby }"  class="col-12">
                  <b-form-checkbox-group id="checkbox-group-2" v-model="data.content_temp" :aria-describedby="ariaDescribedby" name="content-temp-2" class="row">
                    <b-form-checkbox v-for="(row, index) in content_temp_list" :value="row.col_name" :key="row.col_name" :disabled="isColDisabled(row.col_name)" class="col-md-5 mt-5 mb-5">{{ row.label }}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5" v-if="slug == 'SalesInvoice' || slug == 'GeneralSales'">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <h4>{{ $t('print_setting.product_table_details') }}</h4>
              </div>
              <div class="d-flex col-lg-12 mb-5">
                <b-form-group label="" v-slot="{ ariaDescribedby }"  class="col-12">
                  <b-form-checkbox-group id="checkbox-group-2" v-model="data.product_table_setting" :aria-describedby="ariaDescribedby" name="content-temp-2" class="row">
                    <b-form-checkbox v-for="(row, index) in product_table_setting_list" :value="row.col_name" :key="row.col_name"  class="col-md-5 mt-5 mb-5">{{ row.label }}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {VueEditor } from "vue2-editor";

export default {
  name: "from-print-settings-details",
  components: {
    VueEditor
  },
  data() {
    return {
      mainRoute: 'settings/print_settings',
      subMainRoute: 'settings/print_setting',
      mainRouteDependency: 'base/dependency',

      data: {
        header_temp: null,
        header_height: null,
        content_temp: [],
        product_table_setting: [],
        is_multi_language_label: false,
        is_use_watermark: false,
        is_repeated_header: false,
        is_repeated_footer: false,
        is_empty_row: false,
        is_banking_details: false,
        is_break_before_footer: false,
        is_payment_details: false,
        is_signature: false,
        footer_font_color: '#000',
        usage_policy: null,

        is_show_contact: false,
        is_show_customer_details: false,
        is_show_terms_and_conditions: false,
        products_template_design: 1,
        print_size: '80',
        description: {ar: '', en: ''},
      },
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      slug: this.$route.params.slug ? this.$route.params.slug : null,
      isEditing: false,
      validation: null,
      template_list: [],
      content_temp_list: [],
      is_company_have_tax: false,
      disabled_tax: [],
      hide_options: [],
      product_table_setting_list: [
          {col_name:'subtotal_before_tax', label: this.$t('print_setting.subtotal_before_tax')},
          {col_name:'subtotal_after_tax', label: this.$t('print_setting.subtotal_after_tax')},
          {col_name:'subtotal_before_discount', label: this.$t('print_setting.subtotal_before_discount')},
          {col_name:'subtotal_after_discount', label: this.$t('print_setting.subtotal_after_discount')},
          {col_name:'amount_tax', label: this.$t('print_setting.amount_tax')},
          {col_name:'tax_applied', label: this.$t('print_setting.tax_applied')},
          {col_name:'discount_calc', label: this.$t('print_setting.discount_calc')},
          {col_name:'unit', label: this.$t('print_setting.unit')},
          {col_name:'serial_number', label: this.$t('print_setting.serial_number')},
      ],
    };
  },

  methods: {
    save() {
      this.update();
    },
    update() {
      ApiService.put(this.mainRoute + '/' + this.idEditing, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/print-settings');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    // create() {
    //   ApiService.post(this.mainRoute, {
    //     ...this.data,
    //   }).then((response) => {
    //     this.validation = null;
    //     this.$successAlert(response.data.message);
    //   }).catch((error) => {
    //     this.$errorAlert(error);
    //     this.validation = error.response ? error.response.data.errors : null;
    //   });
    // },

   async  getData() {
       await  ApiService.get(`${this.mainRoute}/${this.idEditing}`).then((response) => {
          this.data.header_temp = response.data.data.header_temp ? response.data.data.header_temp : null;
          this.data.content_temp = response.data.data.content_temp ? response.data.data.content_temp : [];
          this.data.product_table_setting = response.data.data.product_table_setting ? response.data.data.product_table_setting : [];
          this.data.header_height = response.data.data.header_height ? response.data.data.header_height : null;
          this.data.is_multi_language_label = response.data.data.is_multi_language_label ? response.data.data.is_multi_language_label : false;
          this.data.is_use_watermark = response.data.data.is_use_watermark ? response.data.data.is_use_watermark : false;

          this.data.is_banking_details = response.data.data.is_banking_details ? response.data.data.is_banking_details : false;
          this.data.is_break_before_footer = response.data.data.is_break_before_footer ? response.data.data.is_break_before_footer : false;
          this.data.is_payment_details = response.data.data.is_payment_details ? response.data.data.is_payment_details : false;
          this.data.footer_font_color = response.data.data.footer_font_color ? response.data.data.footer_font_color : '#000';
          this.data.usage_policy = response.data.data.usage_policy ? response.data.data.usage_policy : null;
          this.is_company_have_tax = (response.data.data.is_company_have_tax ? response.data.data.is_company_have_tax : false);
          this.data.is_repeated_header = response.data.data.is_repeated_header ? response.data.data.is_repeated_header : false;
          this.data.is_repeated_footer = response.data.data.is_repeated_footer ? response.data.data.is_repeated_footer : false;
          this.data.is_empty_row = response.data.data.is_empty_row ? response.data.data.is_empty_row : false;
          this.disabled_tax = response.data.data.disable_tax ? response.data.data.disable_tax : [];
          this.data.is_signature = response.data.data.is_signature ? response.data.data.is_signature : false;

          this.data.is_show_contact = response.data.data.is_show_contact ? response.data.data.is_show_contact : false;
          this.data.is_show_customer_details = response.data.data.is_show_customer_details ? response.data.data.is_show_customer_details : false;
          this.data.is_show_terms_and_conditions = response.data.data.is_show_terms_and_conditions ? response.data.data.is_show_terms_and_conditions : false;
          this.data.products_template_design = response.data.data.products_template_design ? response.data.data.products_template_design : 1;
          this.hide_options = response.data.data.hide_options ? response.data.data.hide_options : [];
          this.data.print_size = response.data.data.print_size ? response.data.data.print_size : '80';
          this.data.description.ar = response.data.data.description ? response.data.data.description : '';
          this.data.description.en = response.data.data.description_en ? response.data.data.description_en : '';
        });
    },
    async getTemplateList() {
      await ApiService.get(this.mainRouteDependency + "/template_type").then((response) => {
        this.template_list = response.data.data;
      });
    },
    async getContentTempList() {
      await ApiService.get(this.subMainRoute + "/doc_type_contant?slug="+this.slug).then((response) => {
        response.data.data.forEach((row)=>{
          if (row.column_label){
            row.column_label.forEach((col)=>{
               this.content_temp_list.push(col);
            })
          }
        });
      });
    },
    isColDisabled: function (col_name){
      if (!this.is_company_have_tax){
        if (this.disabled_tax.includes(col_name)){
          let index = this.data.content_temp.indexOf(col_name);
          if (index !== -1) {
            this.data.content_temp.splice(index, 1);
          }
          return true;
        }else {
          return false;
        }
      }else {
        return false;
      }
    },
    isFieldHide(col_name){
        if (this.hide_options.includes(col_name)){
          return false;
        }else {
          return true;
        }

    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.print_settings"), route: '/settings/print-settings'}, {title: this.$t("MENU.print_settings_details")}]);

    let promise1 = this.getTemplateList();
    let promise2 = this.getData();

    Promise.all([promise1,promise2]).then(()=>{
      this.getContentTempList();
    })
  },
};
</script>


